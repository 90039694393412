import Arg from '@livongo/arg';
import capitalize from 'lodash/capitalize';
import Cookies from 'universal-cookie';
import MixpanelUtils from '@livongo/utils/mixpanel';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
// import {getCurrentVariant} from 'utilities/vwo-utils';
import {
    mixpanelEvent,
    mixpanelEventProperty,
    utmKeywords,
} from 'constants/mixpanel';
import {
    devConsoleLog,
    getFirstNonEmptyKey,
    getVWOVariant,
    getRegistrationContext,
    getTitleFromGRPC,
    getIsRedirectFromTeams,
} from './utils';

/**
 * Populate Mixpanel properties based on the data return from BE.
 *
 * @param {object} registrationStep - Tracking data.
 * @returns {object} Mixpanel properties with populated value.
 */
const arg = Arg.all();
const cookies = new Cookies();

export const populateMixpanelProperty = registrationStep => {
    const contentId = registrationStep.contentId
        ? registrationStep.contentId
        : registrationStep.errorsList
        ? registrationStep.errorsList[0].contentId
        : '';
    const questions = registrationStep?.questionsToAsk?.questionsList || [];

    /*
     * Convert question ids from a array of number value to a delimited string.
     * Example output will be 'FIRST_NAME;LAST_NAME'.
     * Format is requested by business.
     */
    const questionNames = !questions
        ? ''
        : questions
              .map(question => {
                  const qId = question?.questionId?.id || -1;

                  return getQuestionNameById(qId);
              })
              .join(';');

    const questionType = !questions
        ? ''
        : questions
              .map(question => {
                  const qType = question?.dataType;

                  return getFirstNonEmptyKey(qType);
              })
              .join(';');
    const mixpanelProperty = {};
    const vwoExperiments = getVWOVariant() ?? {};

    mixpanelProperty[mixpanelEventProperty.CONTENT_ID] = contentId;
    mixpanelProperty[mixpanelEventProperty.QUESTION_ID] = questionNames;
    mixpanelProperty[mixpanelEventProperty.DATATYPE] = questionType;
    // mixpanelProperty[mixpanelEventProperty.VWO_VARIANT] = getCurrentVariant(); // this is for vwo a/b test
    mixpanelProperty[mixpanelEventProperty.VWO_VARIANT] =
        vwoExperiments.experimentGroup; // this is for vwo split url test
    mixpanelProperty[mixpanelEventProperty.TITLE] = getTitleFromGRPC(); // this is for vwo split url test

    return mixpanelProperty;
};

export const getCurrentProperties = () => {
    const properties = {};
    const savedCookies = cookies.get('utm_parameters') || {};

    if (typeof window !== 'undefined') {
        utmKeywords.forEach(kw => {
            const value = arg[kw] || savedCookies[kw];

            if (value?.length) {
                properties[`${kw} [current]`] = value;
            }
        });
    }

    return properties;
};

export const setSuperAndPeopleProperties = () => {
    const properties = {};
    const firstTouchProperties = {};

    if (typeof window !== 'undefined') {
        const {client = 'Reg5'} = getRegistrationContext() ?? {};
        const savedCookies = cookies.get('utm_parameters') || {};

        properties.Location = 'REG 5.0';
        properties.CCID = arg.ccid;
        properties.Customization = arg.landing_page_customization;
        properties.RegistrationContext = capitalize(client);
        properties['Livongo Tracking ID'] = arg.tracking_id;
        properties['Reg Code'] = arg.regcode;
        properties['Experiment ID'] = arg.vwo;

        if (getIsRedirectFromTeams()) {
            properties['Initial App Type'] = 'MS Teams';
        }

        utmKeywords.forEach(kw => {
            const value = arg[kw] || savedCookies[kw];

            if (value?.length) {
                firstTouchProperties[`${kw} [first touch]`] = value;
                properties[`${kw} [last touch]`] = value;
            }
        });

        MixpanelUtils.setPeoplePropsOnce(firstTouchProperties);
        MixpanelUtils.setPeopleProps(properties);
        MixpanelUtils.setSuperPropsOnce(firstTouchProperties);
        MixpanelUtils.setSuperProps(properties);
    }
};

export const mixpanelPageLand = registrationStep => {
    MixpanelUtils.track({
        event: mixpanelEvent.PAGE_LAND,
        properties: {
            ...populateMixpanelProperty(registrationStep),
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelPagePass = registrationStep => {
    MixpanelUtils.track({
        event: mixpanelEvent.PAGE_PASS,
        properties: {
            ...populateMixpanelProperty(registrationStep),
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelUnableToContinue = registrationStep => {
    MixpanelUtils.track({
        event: mixpanelEvent.UNABLE_TO_CONTINUE,
        properties: {
            ...populateMixpanelProperty(registrationStep),
            ...getCurrentProperties(),
        },
    });
};

// basically navigation next/back will call this function, because this func needs registrationStep param
export const mixpanelButtonClicked = (
    registrationStep,
    buttonLabel,
    buttonCopy
) => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.BUTTON_LABEL] = buttonLabel;
    mixpanelProperty[mixpanelEventProperty.BUTTON_COPY] = buttonCopy;

    if (buttonCopy === 'CTA') {
        MixpanelUtils.setSuperProps({
            // eslint-disable-next-line
            Last_CTA: buttonLabel,
        });
    }
    MixpanelUtils.track({
        event: mixpanelEvent.BUTTON_CLICKED,
        properties: {
            ...mixpanelProperty,
            ...populateMixpanelProperty(registrationStep),
            ...getCurrentProperties(),
        },
    });
};

// all other button clicked event will call this function.
export const mixpanelButtonClickedEvent = (
    questionId,
    contentId,
    buttonLabel,
    buttonCopy
) => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.QUESTION_ID] = questionId;
    mixpanelProperty[mixpanelEventProperty.CONTENT_ID] = contentId;
    mixpanelProperty[mixpanelEventProperty.BUTTON_LABEL] = buttonLabel;
    mixpanelProperty[mixpanelEventProperty.BUTTON_COPY] = buttonCopy;

    if (buttonCopy === 'CTA') {
        MixpanelUtils.setSuperProps({
            // eslint-disable-next-line
            Last_CTA: buttonLabel,
        });
    }

    MixpanelUtils.track({
        event: mixpanelEvent.BUTTON_CLICKED,
        properties: {
            ...mixpanelProperty,
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelSessionTimeOut = registrationStep => {
    MixpanelUtils.track({
        event: mixpanelEvent.SESSION_TIME_OUT,
        properties: {
            ...populateMixpanelProperty(registrationStep),
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelSessionContinuedBeforeTimeOut = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.SESSION_CONTINUED_BEFORE_TIME_OUT,
        properties: getCurrentProperties(),
    });
};

export const mixpanelSessionRestart = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.SESSION_RESTART,
        properties: getCurrentProperties(),
    });
};

export const mixpanelUserStartOver = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.START_OVER,
        properties: getCurrentProperties(),
    });
};

export const mixpanelAccountCreated = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.ACCOUNT_CREATED,
        properties: getCurrentProperties(),
    });
};

export const mixpanelUpsellProgramEnrolled = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.UPSELL_PROGRAM_ENROLLED,
        properties: getCurrentProperties(),
    });
};

export const mixpanelUserLoginSuccessful = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.USER_LOGIN_SUCCESSFUL,
        properties: getCurrentProperties(),
    });
};

export const mixpanelUserLoginFailed = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.USER_LOGIN_FAILED,
        properties: getCurrentProperties(),
    });
};

export const mixpanelUserSSOSuccessful = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.USER_SSO_SUCCESSFUL,
        properties: getCurrentProperties(),
    });
};

export const mixpanelUserSSOFailed = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.USER_SSO_FAILED,
        properties: getCurrentProperties(),
    });
};

export const mixpanelSSOBeforeAuthAndNav = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.SSO_BEFORE_AUTHANDNAV,
        properties: getCurrentProperties(),
    });
};

export const mixpanelGeneralError = ({name, location, additionalInfo}) => {
    devConsoleLog('General Error', {name, location, additionalInfo});

    MixpanelUtils.track({
        event: mixpanelEvent.GENERAL_ERROR,
        properties: {
            name,
            location,
            additionalInfo,
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelAnswerError = (qId, qIdStr, errorType, additionalInfo) => {
    const vwoExperiments = getVWOVariant() ?? {};

    MixpanelUtils.track({
        event: mixpanelEvent.PAGE_ERROR,
        properties: {
            questionName: qIdStr,
            errorType,
            'VWO Variant': vwoExperiments.experimentGroup,
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelPdfViewedEvent = (
    termType,
    questionId,
    contentId,
    modalId
) => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.TERM_TYPE] = termType;
    mixpanelProperty[mixpanelEventProperty.QUESTION_ID] = questionId;
    mixpanelProperty[mixpanelEventProperty.CONTENT_ID] = contentId;
    mixpanelProperty[mixpanelEventProperty.MODAL_ID] = modalId;

    MixpanelUtils.track({
        event: mixpanelEvent.PDF_VIEWED,
        properties: {...mixpanelProperty, ...getCurrentProperties()},
    });
};

export const mixpanelRegcodeDetailsEvent = regCodeDetails => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.FORGOT_REGCODE_SUBMISSION] =
        regCodeDetails?.regcodeSubmission;
    mixpanelProperty[mixpanelEventProperty.FORGOT_REGCODE_TALLIED] =
        regCodeDetails?.regcodeTallied;

    MixpanelUtils.track({
        event: mixpanelEvent.FORGOT_REGCODE,
        properties: {...mixpanelProperty, ...getCurrentProperties()},
    });
};

export const mixpanelModalViewedEvent = (
    modalDescription,
    contentId,
    questionId
) => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.MODAL_DESCRIPTION] =
        modalDescription;
    mixpanelProperty[mixpanelEventProperty.CONTENT_ID] = contentId;
    mixpanelProperty[mixpanelEventProperty.QUESTION_ID] = questionId;
    MixpanelUtils.track({
        event: mixpanelEvent.MODAL_VIEWED,
        properties: {...mixpanelProperty, ...getCurrentProperties()},
    });
};

export const mixpanelModalClosedEvent = (
    modalDescription,
    contentId,
    questionId
) => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.MODAL_DESCRIPTION] =
        modalDescription;
    mixpanelProperty[mixpanelEventProperty.CONTENT_ID] = contentId;
    mixpanelProperty[mixpanelEventProperty.QUESTION_ID] = questionId;

    MixpanelUtils.track({
        event: mixpanelEvent.MODAL_CLOSED,
        properties: {...mixpanelProperty, ...getCurrentProperties()},
    });
};

export const mixpanelOneappSkipForNow = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.ONEAPP_SKIP_FOR_NOW,
        properties: getCurrentProperties(),
    });
};

export const mixpanelEnrollmentExperimentEvent = (
    experimentName,
    experimentGroup,
    triggerLocation,
    additionalProperties = {} // additional Properties, it has to be obj with key value pair
) => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.EXPERIMENT_NAME] = experimentName;
    mixpanelProperty[mixpanelEventProperty.EXPERIMENT_GROUP] = experimentGroup;
    mixpanelProperty[mixpanelEventProperty.TRIGGER_LOCATION] = triggerLocation;

    Object.keys(additionalProperties).forEach(key => {
        mixpanelProperty[key] = additionalProperties[key];
    });

    MixpanelUtils.track({
        event: mixpanelEvent.ENROLLMENT_EXPERIMENT,
        properties: {
            ...mixpanelProperty,
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelProgramSelectionView = (programList, visitsNumber) => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.PROGRAM_OFFERED] = programList;
    mixpanelProperty[mixpanelEventProperty.NUMBER_PROGRAM_OFFERED] =
        programList.length;
    mixpanelProperty['Program Selection Page Visits Number'] = visitsNumber;

    MixpanelUtils.track({
        event: mixpanelEvent.PROGRAM_SELECTION_VIEW,
        properties: {
            ...mixpanelProperty,
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelProgramSelectionClick = (
    selectedProgramList,
    deselectedProgramList,
    visitsNumber
) => {
    const mixpanelProperty = {};

    mixpanelProperty[mixpanelEventProperty.BUTTON_LABEL] = 'Tell us about you';
    mixpanelProperty[mixpanelEventProperty.PROGRAM_SELECTED] =
        selectedProgramList;
    mixpanelProperty[mixpanelEventProperty.NUMBER_PROGRAM_SELECTED] =
        selectedProgramList.length;
    mixpanelProperty[mixpanelEventProperty.PROGRAM_DESELECTED] =
        deselectedProgramList;
    mixpanelProperty[mixpanelEventProperty.NUMBER_PROGRAM_DESELECTED] =
        deselectedProgramList.length;
    mixpanelProperty['Program Selection Page Visits Number'] = visitsNumber;

    MixpanelUtils.track({
        event: mixpanelEvent.PROGRAM_SELECTION_CLICK,
        properties: {
            ...mixpanelProperty,
            ...getCurrentProperties(),
        },
    });
};

export const mixpanelClickedTeladocLogo = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.CLICKED_TELADOC_LOGO,
        properties: getCurrentProperties(),
    });
};

export const mixpanelPageHide = () => {
    MixpanelUtils.track({
        event: mixpanelEvent.PAGE_HIDE,
        properties: getCurrentProperties(),
    });
};

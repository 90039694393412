import {SectionId as MedOptSectionId} from 'api/protobuf/medOpt/prototypes';
import {SectionId as RegistrationSectionId} from 'api/protobuf/enrollment/protoTypes';
import {AlertState as BiometricAlertSectionId} from 'api/protobuf/biometricAlerts/protoTypes';
import {SECTION_TYPES} from 'constants/type';

const {
    Values: {SIGN_UP, COVERAGE, ABOUT_YOU, WELCOME, CONSENT},
} = RegistrationSectionId;

const {
    Values: {SIGNUP, WEB_SDK, ALL_DONE},
} = MedOptSectionId;

const {
    Values: {
        MEDOPT_INITIATED_SIGNUP,
        MEDOPT_ELIGIBILITY_CHECK_PASSED,
        MEDOPT_COMPLETED_ONBOARDING,
        MEDOPT_ENROLLMENT_COMPLETED,
    },
} = BiometricAlertSectionId;

const Config = {
    memberSupportEmail: 'membersupport@livongo.com',
    memberHelpEmail: 'help@livongo.com',
    memberSupportUrl:
        'https://clinical-livongo.force.com/livongoMemberSupport/s/',
    zendeskUrl: `https://livongo.zendesk.com/hc/`,
    healthyEating: `https://goto.livongo.com/he`,
    LivongoDiabetesGuide: `https://sms.livongo.com/2f7cc`,
    // TODO identify stable link
    oneAppMobileAppLink: 'https://go.onelink.me/agxg/589cdff7',
    smsIframe: 'https://livongoapp.app.link/install',
    myStrengthLink: 'https://mystrength.app.link',
    memberSupportPhoneNumber: '8009454355',
    memberSupportTextNumber: '6502521343',
    oneAppMemberSupportPhoneNumber: '8008352362',
    memberSupportFormattedPhoneNumber: '(800) 945-4355',
    memberSupportFormattedTextNumber: '(650) 252-1343',
    oneAppMemberSupportFormattedPhoneNumber: '(800) 835-2362',
    regURL: `signup.livongo.com`,
    tosUrl: `https://goto.livongo.com/terms-of-service`,
    privacyPracticeUrl: `https://goto.livongo.com/web-privacy-practice`,
    nlaUrl: `https://goto.livongo.com/notice-language-assistance`,
    mcpUrl: `https://goto.livongo.com/member-communication-policy`,
    childrenPrivacyUrl: `https://goto.livongo.com/children-online-privacy`,
    websitePrivacyPolicyUrl: `https://goto.livongo.com/web-privacy-policy`,
    legalDisclaimerUrl: `https://goto.livongo.com/legal-disclaimer`,
    noticeOfNonDiscriminationUrl: `https://goto.livongo.com/notice-of-non-discrimination`,
    GINAUrl: 'https://content.livongo.com/docs/GINA.pdf',
    dignityHealthTermsUrl: 'https://www.dignityhealth.org/toulivongo',
    oneAppUrl: 'https://my.teladoc.com',
    teladocUrl: `teladochealth.com`,
    memberPortalURL: 'My.Livongo.com',
    teamsDeeplinkUrl:
        'https://teams.microsoft.com/l/entity/TEAMS_APP_ID/conversations',
    nameInputPattern: '[-0-9A-zÀ-ž ]+',
    addressInputPattern: '[-.0-9A-zÀ-ž #]+',

    idleTimerDebounce: 1000,
    idleTimerTimeout: 900000,

    // generic Ids any application for information needed for to store in localstorage.
    applicationName: 'application_name',
    userUUID: '_user_uuid',
    correlationId: '_correlationId',
    isVisited: '_is_visited',
    isAuthenticated: '_is_authenticated',
    userSessionExpires: '_user_session_expires',
    grpcAccessToken: '_grpc_access_token',
    grpcAccessTokenTimeStamp: '_grpc_access_token_timestamp',
    teladocSdkToken: '_teladoc_sdk_token',
    webSdkInitialState: '_web_sdk_initial_state',
    restApiAccessToken: '_rest_api_access_token',
    restApiRefreshToken: '_rest_api_refresh_token',
    restApiAccessCode: '_rest_api_access_code',
    registrationContext: '_context',
    ssoContext: '_ssoContext',
    regCode: '_reg_code',
    stepUrls: '_stepUrls',
    stepIndex: '_stepIndex',
    stepLoader: '_stepLoader',

    oneAppPortal: 'oneAppPortal',
    isRedirectFromTeams: 'is_from_teams',
    upsellProgram: 'upsell_program',
    MEMBER_ID: 'member_id',
    TDOC_AUTH_TOKEN: 'member_auth_token',
    USER_LOCALE_COOKIE: 'user_preferred_language',

    // Ids for registration specific information needed to store in localstorage.
    userEmail: 'registration_user_email',
    clientFriendlyName: 'registration_client_friendly_name',
    vwoVariant: 'registration_vwo_variant',
    allOfferedPrograms: 'registration_offered_programs',
    partner: 'registration_partner',
    isEmailQuestionAsked: 'registration_is_email_question_asked',
    callBackUrl: 'registration_call_back_url',
    savedAddress: 'registration_saved_address',
    grpcTitle: 'registration_grpc_title',
    stepNumber: 'registration_step_number',
    userProgress: 'registration_user_progress',

    registerStartUrl: '/registration',
    upsellUrl: '/upsell',
    loginUrl: '/login',
    ssoUrl: '/sso',
    bhSSOUrl: '/bh-sso',
    medOptUrl: '/med-opt',
    coverageConflictUrl: '/coverageConflict',
    welcomeBackUrL: '/welcomeBack',
    linkedAccount: '/linkedAccount',

    consentSection: {
        id: CONSENT,
        title: 'consent',
        pathname: 'consent',
        layout: 'WideNarrow',
        type: SECTION_TYPES.QUESTION,
    },

    sectionsDefault: [
        {
            id: SIGN_UP,
            title: 'signUp',
            pathname: 'sign-up',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        // {
        //     id: CONSENT,
        //     title: 'Hello',
        //     pathname: 'hello',
        //     layout: 'WideNarrow',
        //     type: SECTION_TYPES.QUESTION,
        // },
        {
            id: COVERAGE,
            title: 'coverage',
            pathname: 'coverage',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: ABOUT_YOU,
            title: 'aboutYou',
            pathname: 'about-you',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: WELCOME,
            title: 'welcome',
            pathname: 'welcome',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
    ],

    upsellSections: [
        {
            id: ABOUT_YOU,
            title: 'aboutYou',
            pathname: 'about-you',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: WELCOME,
            title: 'welcome',
            pathname: 'welcome',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
    ],

    medOptSectionsDefault: [
        {
            id: SIGNUP,
            title: 'getStarted',
            pathname: 'get-started',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: WEB_SDK,
            title: 'pcpIntakeForm',
            pathname: 'pcp-intake-form',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: ALL_DONE,
            title: 'getTheApp',
            pathname: 'get-the-app',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
    ],

    biometricAlertSectionsDefault: [
        {
            id: MEDOPT_INITIATED_SIGNUP,
            title: 'getStarted',
            pathname: 'get-started',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: MEDOPT_ELIGIBILITY_CHECK_PASSED,
            title: 'pcpIntakeForm',
            pathname: 'pcp-intake-form',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: MEDOPT_COMPLETED_ONBOARDING,
            title: 'getTheApp',
            pathname: 'get-the-app',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: MEDOPT_ENROLLMENT_COMPLETED,
            title: 'enrollmenCompleted',
            pathname: 'enrollment-completed',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
    ],

    vwoExperiment: {},

    client: {
        reg5: 'reg5',
        galaxy: 'galaxy',
        salesforce: 'salesforce',
        sso: 'sso',
        oneapp: 'oneapp',
    },
    MedoptFlavor: {
        INVALID: 0,
        DM_HTN: 1,
        WM_DPP: 2,
    },
    pbcExp: {
        pbc: 'pbc',
        pbce: 'WM_DPP',
    },
    baseUrlPrefix: 'mobile-api',
    livongoBundleIdentifier: 'com.livongo',
    oneAppBundleIdentifier: 'com.teladoc',
};

export default Config;
